<template>
  <div>
    <div class="lg:hidden">
      <div
        class="
          px-6
          py-2
          flex flex-col
          text-text-primary
          lg:mt-32 lg:min-h-screen
        "
      >
        <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
          <button class="focus:outline-none" @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto font-semibold">Refer & Earn</h2>
        </header>
        <BPageLoader
          v-if="isLoading || bankResponse.isLoading"
          class="flex-grow"
        />
        <main v-else>
          <div
            class="block rounded bg-bg-alternate-9 p-4 sm:py-6 my-2 text-center"
          >
            <h5 class="font-medium text-xs text-text-alternate-13">
              REFERRAL BONUS
            </h5>
            <p class="font-bold text-lg pt-2">
              ₦ {{ data.referralBonus.toLocaleString() }}
            </p>
          </div>
          <button
            class="
              font-bold
              text-sm text-text-primary
              rounded
              border
              py-3.5
              w-full
              mb-2
              focus:outline-none
            "
            @click="makeWithdrawal"
            :disabled="!canWithdrawal || withdrawalResponse.isLoading"
          >
            <BButtonLoader
              v-if="withdrawalResponse.isLoading"
              class="mx-auto h-4 w-4"
            />
            <span v-else>Withdraw</span>
          </button>
          <div class="py-3 text-center">
            <h3 class="text-lg font-semibold text-text-alternate-1 my-4">
              Earn {{ data.referralPercentage }}% per referral.
            </h3>
            <p class="text-xs my-4 font-medium">
              Get {{ data.referralPercentage }}% of total donations from the
              first Fundraiser Cycle for every successful referral you make.
              Earn money by spreading the word about Blessmi.
            </p>
          </div>
          <p
            class="
              text-xs
              font-medium
              text-center text-text-alternate-1
              mt-1
              mb-6
            "
          >
            INVITED FRIENDS :
            <span class="font-bold">
              {{ data.invitedFriends }}
            </span>
          </p>

          <hr class="my-4 text-text-alternate-8" />
          <p
            class="
              rounded
              bg-bg-alternate-3
              p-4
              my-6
              text-center
              font-bold
              text-lg
            "
          >
            {{ data.referralCode }}
          </p>
          <ul class="text-sm">
            <li class="my-7">
              <button
                class="
                  flex
                  items-center
                  text-text-alternate-1
                  font-semibold
                  focus:outline-none
                "
                @click="handleCopy"
              >
                <CopyIcon
                  class="h-5 stroke-0 stroke-current fill-current mr-4"
                  style="fill: #577387"
                />
                Copy Code
              </button>
            </li>
            <li class="my-7">
              <button
                class="
                  flex
                  items-center
                  text-text-alternate-1
                  font-semibold
                  focus:outline-none
                "
                @click="copyReferal"
              >
                <LinkIcon
                  class="h-5 stroke-0 stroke-current fill-current mr-4"
                  style="fill: #577387"
                />
                Generate Referral Link
              </button>
            </li>
            <li class="my-7">
              <button
                class="
                  flex
                  items-center
                  text-text-alternate-1
                  font-semibold
                  focus:outline-none
                "
                @click="handleShare"
              >
                <ShareIcon
                  class="h-5 stroke-2 stroke-current mr-4"
                  style="stroke: #577387"
                />
                Share
              </button>
            </li>
          </ul>
        </main>
      </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8">
        <div class="px-26 py-2 flex flex-col text-text-primary">
          <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
            <button class="focus:outline-none" @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto font-semibold">Refer & Earn</h2>
          </header>
          <BPageLoader
            v-if="isLoading || bankResponse.isLoading"
            class="flex-grow"
          />
          <main v-else>
            <div
              class="
                block
                rounded
                bg-bg-alternate-9
                p-4
                sm:py-6
                my-2
                text-center
              "
            >
              <h5 class="font-medium text-xs text-text-alternate-13">
                REFERRAL BONUS
              </h5>
              <p class="font-bold text-lg pt-2">
                ₦ {{ data.referralBonus.toLocaleString() }}
              </p>
            </div>
            <button
              class="
                font-bold
                text-sm text-text-primary
                rounded
                border
                py-3.5
                w-full
                mb-2
                focus:outline-none
              "
              @click="makeWithdrawal"
              :disabled="!canWithdrawal || withdrawalResponse.isLoading"
            >
              <BButtonLoader
                v-if="withdrawalResponse.isLoading"
                class="mx-auto h-4 w-4"
              />
              <span v-else>Withdraw</span>
            </button>
            <div class="py-3 text-center">
              <h3 class="text-lg font-semibold text-text-alternate-1 my-4">
                Earn {{ data.referralPercentage }}% per referral.
              </h3>
              <p class="text-xs my-4 font-medium">
                Get {{ data.referralPercentage }}% of total donations from the
                first Fundraiser Cycle for every successful referral you make.
                Earn money by spreading the word about Blessmi.
              </p>
            </div>
            <p
              class="
                text-xs
                font-medium
                text-center text-text-alternate-1
                mt-1
                mb-6
              "
            >
              INVITED FRIENDS :
              <span class="font-bold">
                {{ data.invitedFriends }}
              </span>
            </p>

            <hr class="my-4 text-text-alternate-8" />
            <p
              class="
                rounded
                bg-bg-alternate-3
                p-4
                my-6
                text-center
                font-bold
                text-lg
              "
            >
              {{ data.referralCode }}
            </p>
            <ul class="text-sm">
              <li class="my-7">
                <button
                  class="
                    flex
                    items-center
                    text-text-alternate-1
                    font-semibold
                    focus:outline-none
                  "
                  @click="handleCopy"
                >
                  <CopyIcon
                    class="h-5 stroke-0 stroke-current fill-current mr-4"
                    style="fill: #577387"
                  />
                  Copy Code
                </button>
              </li>
              <li class="my-7">
                <button
                  class="
                    flex
                    items-center
                    text-text-alternate-1
                    font-semibold
                    focus:outline-none
                  "
                  @click="copyReferal"
                >
                  <LinkIcon
                    class="h-5 stroke-0 stroke-current fill-current mr-4"
                    style="fill: #577387"
                  />
                  Generate Referral Link
                </button>
              </li>
              <li class="my-7">
                <button
                  class="
                    flex
                    items-center
                    text-text-alternate-1
                    font-semibold
                    focus:outline-none
                  "
                  @click="handleShare"
                >
                  <ShareIcon
                    class="h-5 stroke-2 stroke-current mr-4"
                    style="stroke: #577387"
                  />
                  Share
                </button>
              </li>
            </ul>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, ref, watch, computed } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import {
  getUserBank,
  getUserReferal,
  postReferalWithdrawal,
} from '@/services/api';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { useAppState } from '@/cmp-functions/appState.js';

export default {
  name: 'Refer',

  setup() {
    const store = useStore();
    const showAccountWallet = ref(false);
    const authUser = store.state.auth.authData;
    const toast = useToast();

    const [bankResponse, fetchUserBank] = useApi(getUserBank);
    const [response, fetchUserReferal] = useApi(getUserReferal);
    const { isNative } = useAppState();
    const [withdrawalResponse, makeReferalWithdrawal] = useApi(
      postReferalWithdrawal
    );
    fetchUserBank(authUser.id);
    fetchUserReferal(authUser.id);

    const handleCopy = async () => {
      try {
        navigator.clipboard.writeText(response.data.referralCode);
        toast.success('Copied to clipboard!');
      } catch (error) {
        console.log(error);
      }
    };

    const copyReferal = async () => {
      try {
        const url = `${window.location.origin}/signup?referral=${response.data.referralCode}`;
        navigator.clipboard.writeText(url);
        toast.success('Copied to clipboard!');
      } catch (error) {
        console.log(error);
      }
    };

    const handleShare = async () => {
      const url = `${window.location.origin}/signup?referral=${response.data.referralCode}`;
      if (isNative) {
        window.location.href = `gonative://share/sharePage?url=${encodeURIComponent(
          url
        )}`;
      } else if (navigator.canShare) {
        await navigator.share({
          title: 'Registration',
          description: 'Registration with my code',
          url,
        });
      } else {
        alert('Sharing not supported on web');
      }
    };

    const makeWithdrawal = () => {
      const payload = {
        userId: authUser.id,
        bankId: bankResponse.data.id,
        amount: response.data.referralBonus,
      };
      makeReferalWithdrawal(payload);
    };

    const canWithdrawal = computed(() => {
      const { referralBonus, minimumWithdrawal } = response.data || {};
      return referralBonus >= minimumWithdrawal;
    });

    watch(withdrawalResponse, ({ isSuccess }) => {
      if (isSuccess) {
        response.data.referralBonus = 0;
        toast.success('successful!');
      }
    });

    return {
      ...toRefs(response),
      handleShare,
      canWithdrawal,
      bankResponse,
      showAccountWallet,
      withdrawalResponse,
      makeWithdrawal,
      handleCopy,
      copyReferal,
    };
  },
};
</script>
